<template>

  <div class="fragment">
    <div class="custom-row">
      <div class="custom-col custom-col--50 custom-col--md-100">
        <div class="slider-block" v-bind:class="{'loaded-slider': loadedImage || (loadedImage && HL.data.Files.data.downloadFiles.length === 0)}">
          <div v-if="loadedImage && HL.data.Files.data.downloadFiles.length > 0">
            <agile class="main" ref="main" :options="options1" :as-nav-for="asNavFor1">
              <div class="slide"
                   v-for="(slide, index) in HL.data.Files.data.downloadFiles"
                   :key="index"
                   :class="`slide--${index}`"
              >
                <!--            <img :src="getCurrentFile(slide)"/>-->
                <img class="cursor-pointer"
                     alt="img"
                     :src="`data:${getCurrentFile(slide).type};base64,` + getCurrentFile(slide)['base64']"
                     @click="show(index)">
              </div>
            </agile>
<!--            :as-nav-for="asNavFor2"-->
            <div style="max-width: 490px; margin: 0 auto;" v-if="HL.data.Files.data.downloadFiles.length > 1">
              <agile class="thumbnails" ref="thumbnails" :options="options2" >
                <div class="slide slide--thumbniail"
                     v-for="(slide, index) in HL.data.Files.data.downloadFiles"
                     :key="index" :class="`slide--${index}`"
                     @click="$refs.main.goTo(index)"
                >
                  <img :src="`data:${getCurrentFile(slide).type};base64,` + getCurrentFile(slide)['base64']"
                       alt="img">
                </div>
                <template slot="prevButton"><i v-bind:class="{'d-none' :HL.data.Files.data.downloadFiles.length < 3}" class="fas fa-chevron-left"><BackArrowIco/></i></template>
                <template slot="nextButton"><i v-bind:class="{'d-none' :HL.data.Files.data.downloadFiles.length < 3}" class="fas fa-chevron-right"><BackArrowIco/></i></template>
              </agile>
            </div>

          </div>
        </div>
        <DotsShadowPreloader v-if="!loadedImage"/>
        <img v-if="loadedImage && HL.data.Files.data.downloadFiles.length === 0" src="/img/finance-group/list-no-image.svg" style="width: 70%" alt="img">

      </div>

      <div class="order-create__col custom-col custom-col--50 custom-col--md-100">
        <div class="block-table__row">
          <div class="block-table__col mb-1">
            <div class="card-detail-left__id"> {{HL.data.name}} </div>
          </div>
          <div class="block-table__col mb-3">
            <div class="order-create__head-title">${{HL.data.price}}</div>
          </div>

          <div class="block-table__col block-table__col--50 mb-4">
            <div class="block-table__label admin-edit-item">
              <span class="admin-edit" @click="editTranslate(['heartysan_category'])"></span>
              {{ $t('heartysan_category.localization_value.value') }}
            </div>
            <div class="block-table__content">
              <ValueHelper
                  :value="HL.data.category"
                  :deep="'currentTranslate.name'"
              />
            </div>
          </div>
          <div class="block-table__col block-table__col--50" v-if="HL.data.category && manufacturer">
            <div class="block-table__label admin-edit-item">
              <span class="admin-edit" @click="editTranslate(['heartysan_subCategory'])"></span>
              {{ $t('heartysan_subCategory.localization_value.value') }}
            </div>
            <div class="block-table__content">
              <ValueHelper
                  :value="HL.data.subCategory"
                  :deep="'currentTranslate.name'"
              />
            </div>
          </div>
          <div class="block-table__col block-table__col--50">
            <div class="block-table__label admin-edit-item">
              <span class="admin-edit" @click="editTranslate(['heartysan_country'])"></span>
              {{ $t('heartysan_country.localization_value.value') }}
            </div>
            <div class="block-table__content">
              <ValueHelper
                  :value="HL.data.country"
                  :deep="'label'"
              />
            </div>
          </div>
          <div class="block-table__col block-table__col--50">
            <div class="block-table__label admin-edit-item">
              <span class="admin-edit" @click="editTranslate(['heartysan_quantity'])"></span>
              {{ $t('heartysan_quantity.localization_value.value') }}
            </div>
            <div class="block-table__content">
              {{ HL.data.quantity }}
            </div>
          </div>


          <div class="block-table__col mt-5" >
            <div class="block-table__label admin-edit-item">
              <span class="admin-edit" @click="editTranslate(['heartysan_manufacturer'])"></span>
              {{ $t('heartysan_manufacturer.localization_value.value') }}
            </div>
            <div class="d-flex">
              <img
                  v-if="HL.data.User.user.avatarBase64 && _.has(HL.data.User.user.avatarBase64, 'fileBase64')"
                  style="max-width: 60px; width: 60px; height: 60px; margin-right: 15px; overflow: hidden; border-radius: 50%"
                  :src="`data:${HL.data.User.user.avatarBase64.mime_type};base64,` + HL.data.User.user.avatarBase64.fileBase64"
                  alt="alt"
              >
              <div class="block-table__content">
               {{HL.data.User.userName}}
              </div>
            </div>
          </div>


          <div class="block-table__col mt-5">
            <div class="order-create__footer-btn"
                 v-if="chat"
                 v-bind:class="{'admin-edit-item': isAdmin || checkImpersonate()}">
              <div class="admin-edit" @click="editTranslate(['heartysan_goToChatUser'])"></div>
              <router-link :to="chat">
                <MainButton
                    class="order-create__footer-btn-i wfc"
                    :value="$t('heartysan_goToChatUser.localization_value.value') + ' ' + HL.data.User.userName"
                    v-bind:class="{'disabled-btn' : $store.getters.heartysanProductsBtn}"
                />
              </router-link>
            </div>
            <div class="order-create__footer-btn"
                 v-if="sent"
                 v-bind:class="{'admin-edit-item': isAdmin || checkImpersonate()}">
              <div class="admin-edit" @click="editTranslate(['heartysan_Write'])"></div>
              <MainButton
                  class="order-create__footer-btn-i wfc"
                  :value="$t('heartysan_Write.localization_value.value') + ' ' + HL.data.User.userName"
                  @click.native="$emit('requestProduct', ({user_id: HL.data.manufacturerId, id: HL.data.id}))"
                  v-bind:class="{'disabled-btn' : $store.getters.heartysanProductsBtn}"
              />
            </div>
          </div>

        </div>
      </div>

    </div>


    <!--    <div class="custom-row">-->
    <!--      <div class="custom-col custom-col&#45;&#45;50">-->
    <!--        <div class="site-document d-flex justify-content-center cursor-pointer">-->
    <!--&lt;!&ndash;          <div class="site-document__img">&ndash;&gt;-->
    <!--            <img v-if="bigFile" :src="bigFile"-->
    <!--                 style="max-width: 400px"-->
    <!--                 @click="showSingle(getCurrentFile.typeSize === 'small_image' ? parseInt(getCurrentFile.id) - 1 : getCurrentFile.id)"-->
    <!--                 alt="img">-->
    <!--&lt;!&ndash;            <img :src="`data:${getCurrentFile.type};base64,` + getCurrentFile['base64']"&ndash;&gt;-->
    <!--&lt;!&ndash;                 @click="showSingle(getCurrentFile.typeSize === 'small_image' ? parseInt(getCurrentFile.id) - 1 : getCurrentFile.id)"&ndash;&gt;-->
    <!--&lt;!&ndash;                 alt="img">&ndash;&gt;-->
    <!--&lt;!&ndash;          </div>&ndash;&gt;-->
    <!--        </div>-->
    <!--      </div>-->
    <!--      <div class="custom-col custom-col&#45;&#45;50"-->
    <!--           v-if="Object.keys(HL.data.Files.data.downloadFiles).length > 0"-->
    <!--      >-->
    <!--        <div class="custom-row">-->
    <!--          <div class="custom-col custom-col&#45;&#45;25 custom-col&#45;&#45;md-50 custom-col&#45;&#45;sm-100"-->
    <!--               v-for="(item, index) in getOtherDownloadFiles"-->
    <!--               :key="index">-->
    <!--            <DownloadFileBlock-->
    <!--                :file="item"-->
    <!--            />-->
    <!--          </div>-->
    <!--        </div>-->
    <!--      </div>-->
    <!--    </div>-->

    <div class="order-create__row custom-row">
      <div class="order-create__col custom-col">
        <div class="block-table__row">
          <div class="block-table__col block-table__col--50 block-table__col--md-100 text-justify mt-3">
            <template v-if="!manufacturer">
              <div class="block-table__label admin-edit-item">
                <span class="admin-edit" @click="editTranslate(['heartysan_country'])"></span>
                Похожие товары
              </div>
              <div class="custom-row table-top-padding">
                <div class="custom-col custom-col--50 custom-col--xs-100 custom-col--md-50 custom-col--xl-50"
                     v-for="(item, index) in $store.getters.getHeartysanProducts"
                     :key="index">
                  <div class="case-cart">
                    <div class="case-cart__inner">
                      <div class="case-cart__columns">
                        <div class="case-cart__img-block">
                          <div class="case-cart__img">
                            <img v-if="item.imageBase64" :src="'data:image/png;base64,' + item.imageBase64" alt="img">
                            <img v-else src="/img/finance-group/list-no-image.svg" alt="img">
                          </div>
                          <div class="case-cart__number-block">
                          </div>
                        </div>
                        <div class="case-cart__content-block">
                          <div class="case-cart__head pl-0">
                            <router-link class="case-cart__title case-cart__title--status"
                                         :to="$store.getters.GET_PATHS.heartysanSellerListings + '/' + item.id">
                              <TooltipTextHelper>
                                <template slot="text">
                                  <div class="case-cart__content--description">{{item.name}}</div>
                                </template>
                                <template slot="paragraph">
                                  {{item.name}}
                                </template>
                              </TooltipTextHelper>
                            </router-link>
                          </div>
                          <div class="case-cart__body pl-0">
                            <div class="case-cart__row custom-row">
                              <div class="case-cart__col custom-col custom-col--33">
                                <div class="case-cart__label"
                                     v-bind:class="{'admin-edit-item': isAdmin || checkImpersonate()}">
                                  <div class="admin-edit" @click="editTranslate(['common_id'])"></div>
                                  {{ $t('common_id.localization_value.value') }}
                                </div>
                                <div class="case-cart__content">
                                  {{ item.id }}
                                </div>
                              </div>
                              <div class="case-cart__col custom-col custom-col--33">
                                <div class="case-cart__label"
                                     v-bind:class="{'admin-edit-item': isAdmin || checkImpersonate()}">
                                  <div class="admin-edit" @click="editTranslate(['heartysan_price'])"></div>
                                  {{ $t('heartysan_price.localization_value.value') }}
                                </div>
                                <div class="case-cart__content">
                                  ${{ item.price }}
                                </div>
                              </div>
                              <div class="case-cart__col custom-col">
                                <div class="case-cart__label"
                                     v-bind:class="{'admin-edit-item': isAdmin || checkImpersonate()}">
                                  <div class="admin-edit" @click="editTranslate(['heartysan_quantity'])"></div>
                                  {{ $t('heartysan_quantity.localization_value.value') }}
                                </div>
                                <div class="case-cart__content">
                                  {{ item.production_terms }}
                                </div>
                              </div>
                              <div class="case-cart__col custom-col custom-col">
                                <div class="case-cart__label"
                                     v-bind:class="{'admin-edit-item': isAdmin || checkImpersonate()}">
                                  <div class="admin-edit" @click="editTranslate(['heartysan_description'])"></div>
                                  {{ $t('heartysan_description.localization_value.value') }}
                                </div>
                                <div class="case-cart__content">
                                  <TooltipTextHelper>
                                    <template slot="text">
                                      <div class="case-cart__content--description">{{item.description}}</div>
                                    </template>
                                    <template slot="paragraph">
                                      {{item.description}}
                                    </template>
                                  </TooltipTextHelper>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <!--                    <div class="case-cart__bottom"-->
                      <!--                         v-bind:class="{'admin-edit-item': isAdmin || checkImpersonate()}">-->
                      <!--                      <div class="admin-edit" @click="editTranslate(['heartysan_toArchive', 'common_edit'])"></div>-->
                      <!--                      <div class="case-cart__btn ml-auto"-->
                      <!--                           v-if="!isAdmin">-->
                      <!--                        <CaseCartButton-->
                      <!--                            v-if="item.enable"-->
                      <!--                            class="secondary-brown"-->
                      <!--                            :value="$t('heartysan_toArchive.localization_value.value')"-->
                      <!--                            :ico="true"-->
                      <!--                            :typeIco="'archive-brown'"-->
                      <!--                            @click.native="inArchive(item.id)"-->
                      <!--                        >-->
                      <!--                        </CaseCartButton>-->
                      <!--                        <CaseCartButton-->
                      <!--                            v-else-->
                      <!--                            class="secondary-brown"-->
                      <!--                            :value="$t('heartysan_toUnArchive.localization_value.value')"-->
                      <!--                            :ico="true"-->
                      <!--                            :typeIco="'archive-brown'"-->
                      <!--                            @click.native="inUnArchive(item.id)"-->
                      <!--                        >-->
                      <!--                        </CaseCartButton>-->
                      <!--                      </div>-->
                      <!--                      <div class="case-cart__btn ml-auto"-->
                      <!--                           v-if="!isAdmin">-->
                      <!--                        <router-link :to="$store.getters.GET_PATHS.heartysanListings + '/edit/' + item.id">-->
                      <!--                          <CaseCartButton-->
                      <!--                              :value="$t('common_edit.localization_value.value')"-->
                      <!--                              :ico="true"-->
                      <!--                              :typeIco="'edit'"-->
                      <!--                          >-->
                      <!--                          </CaseCartButton>-->
                      <!--                        </router-link>-->
                      <!--                      </div>-->
                      <!--                    </div>-->
                    </div>
                  </div>
                </div>

              </div>
            </template>
          </div>
          <div class="block-table__col block-table__col--50 block-table__col--md-100 text-justify mt-3">
            <div class="block-table__label admin-edit-item" style="font-size: 16px;">
              <span class="admin-edit" @click="editTranslate(['heartysan_description'])"></span>
              {{ $t('heartysan_description.localization_value.value') }}
            </div>
            <div class="block-table__content block-table__content--description">
              {{ HL.data.description }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <Lightbox
        escDisabled
        moveDisabled
        :visible="visible"
        :imgs="imgs"
        :index="index"
        @hide="handleHide"
    ></Lightbox>

  </div>
</template>

<script>
// import DownloadFileBlock from "@/components/coreComponents/DownloadFileBlock/DownloadFileBlock";
import Lightbox from "vue-easy-lightbox";
import ValueHelper from "@/components/coreComponents/ValueHelper/ValueHelper";
import {VueAgile} from 'vue-agile'
import MainButton from "@/components/UI/buttons/MainButton/MainButton";
import TooltipTextHelper from "@/components/coreComponents/TooltipTextHelper/TooltipTextHelper";
// import {HEARTYSAN_STATUSES} from "@/staticData/staticVariables";
import {queryFilterMixin} from "@/mixins/routeFilterMixins/queryFilterMixin";
import DotsShadowPreloader from "@/components/coreComponents/preloaders/DotsShadowPreloader/DotsShadowPreloader";
import BackArrowIco from '../../../../../../../public/img/UI-group/link-back-arrow-icon.svg?inline'

export default {
  name: "ListingShowBlock",
  components: {
    DotsShadowPreloader,
    TooltipTextHelper,
    MainButton,
    ValueHelper,
    // DownloadFileBlock,
    Lightbox,
    BackArrowIco,
    agile: VueAgile
  },

  mixins: [queryFilterMixin],

  props: {
    HL: Object,
    manufacturer: {
      type: Boolean,
      default: false
    },
    loadedImage: {
      type: Boolean,
      default: false
    },

    chat: {
      type: String,
      default: null,
    },
    sent: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    getOtherDownloadFiles() {
      let files = this._.clone(this.HL.data.Files.data.downloadFiles)
      files.splice(0, 1)
      return files
    },

    // getCurrentFile(){
    //   let firstFile = this._.first(this.HL.data.Files.data.downloadFiles)
    //   return firstFile[Object.keys(firstFile)[0]]
    // },

    getFiles() {
      return Object.hasOwnProperty.call(this.HL.data.Files.data.downloadFiles, '0')
    }
  },

  watch: {
    // getFiles() {
    //   this.getFirstFile()
    // },
  },

  data() {
    return {
      bigFile: null,
      images: -1,

      imgs: [], // Img Url , string or Array of string
      visible: false,
      index: 0, // default: 0


      asNavFor1: [],
      asNavFor2: [],
      options1: {
        dots: false,
        fade: true,
        navButtons: false,
        infinite: false
      },

      options2: {
        autoplay: false,
        centerMode: false,
        dots: false,
        navButtons: true,
        slidesToShow: 4,
        infinite: true,
        responsive: [
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 4
            }
          },

          {
            breakpoint: 1000,
            settings: {
              slidesToShow: 4,
              // navButtons: true
            }
          }
        ]

      },

      slides: []
    }
  },

  mounted() {
    // this.getFirstFile()
    this.asNavFor1.push(this.$refs.thumbnails)
    this.asNavFor2.push(this.$refs.main)

    let myQuery = this.createQueryBuilderObject({withUserNewMark: true})
    // myQuery.where('status', HEARTYSAN_STATUSES.HEARTYSAN_STATUS_ACTIVE.value)
    // myQuery.where('enable', 1)
    let ids = []
    this.HL.data.similarProducts.map(item => {
      ids.push(item.id)
    })
    myQuery.where('id', ids.join(','))
    let url = this.generateGetParamsFromQuery(myQuery, 2, 0)

    this.$store.dispatch('fetchHeartysanProducts', url)
  },

  methods: {
    getCurrentFile(file) {
      let key = Object.keys(file)
      return file[key]
    },

    // getFirstFile() {
    //   if(this.HL.data.Files.data.downloadFiles.length > 0) {
    //     let idFile = Object.keys(this._.first(this.HL.data.Files.data.downloadFiles))[0]
    //     let typeFile = this._.first(this.HL.data.Files.data.downloadFiles)[idFile]?.type
    //     let typeFileSize = this._.first(this.HL.data.Files.data.downloadFiles)[idFile]?.typeSize
    //     this.getBigFile(typeFileSize === 'small_image' ? idFile - 1 : idFile, typeFile, )
    //   }
    // },

    openPdf(base64) {
      this.globalOpenPdfBase64(base64)
    },

    handleHide() {
      this.visible = false
    },

    show(index) {
      this.imgs = []
      this.index = index
      this.HL.data.Files.data.downloadFiles.map(slide => {
        this.imgs.push(`data:${this.getCurrentFile(slide).type};base64,` + this.getCurrentFile(slide)['base64'])
      })
      this.visible = true
    },

    getBigFile(id, typeFile) {
      console.log(id, typeFile);
      // this.$store.dispatch('getFileFromServer', id).then((response) => {
      //   this.bigFile = `data:${typeFile};base64,` + response[id]
      // })
    },

    // showSingle(id) {
    //   this.$store.dispatch('getFileFromServer', id).then((response) => {
    //     this.imgs = []
    //     this.imgs.push({
    //       title: 'img',
    //       src: `data:image/png;base64,` + response[id]
    //     })
    //     this.show()
    //   })
    // },

  }

}
</script>

<style scoped>

.order-create__head-title{
  font-size: 28px;
}

.block-table__label{
  font-size: 16px;
}

.block-table__content:not(.block-table__content--description){
  font-size: 26px;
}

.block-table__content.block-table__content--description{
  font-size: 16px;
}

.order-create__footer-btn-i{
  min-width: 430px;
  height: 48px;
  border-radius: 38px;
}

@media (max-width: 550px) {
  .order-create__footer-btn-i {
    min-width: 320px;
  }
}

.main {
  margin-bottom: 30px;
}

.thumbnails {
  margin: 0 -5px;
  width: calc(100% + 10px);
}

.agile__nav-button {
  background: transparent;
  border: none;
  color: #ccc;
  cursor: pointer;
  font-size: 24px;
  transition-duration: 0.3s;
}

.thumbnails .agile__nav-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.thumbnails .agile__nav-button--prev {
  left: -45px;
}

.thumbnails .agile__nav-button--next {
  right: -45px;
}

.agile__nav-button:hover {
  color: #888;
}

.agile__dot {
  margin: 0 10px;
}

.agile__dot button {
  background-color: #eee;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  display: block;
  height: 10px;
  font-size: 0;
  line-height: 0;
  margin: 0;
  padding: 0;
  transition-duration: 0.3s;
  width: 10px;
}

.agile__dot--current button, .agile__dot:hover button {
  background-color: #888;
}

.slide {
  align-items: center;
  box-sizing: border-box;
  color: #fff;
  display: flex;
  height: 400px;
  justify-content: center;
}

.slide--thumbniail {
  cursor: pointer;
  height: 100px;
  padding: 0 5px;
  transition: opacity 0.3s;
}

.slide--thumbniail:hover {
  opacity: 0.75;
}

.slide img {
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  -o-object-position: center;
  object-position: center;
  width: 100%;
}

.slider-block {
  opacity: 0;
  transition: 0.6s;
}

.slider-block.loaded-slider {
  opacity: 1;
}


.case-cart__content--description{
  overflow: hidden;
  text-overflow: ellipsis;
  display: -moz-box;
  -moz-box-orient: vertical;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  word-break: break-all;
}

.slider-block >>> .agile__nav-button{
  background: transparent;
  border: 0;
  cursor: pointer;
}

.slider-block >>> .agile__nav-button:disabled{
  color: initial;
  opacity: 0.5;
  cursor: initial;
}

/*.fa-chevron-left:after{*/
/*  content: '<';*/
/*  display: block;*/
/*  width: 20px;*/
/*  height: 20px;*/
/*  line-height: 19px;*/
/*  font-style: normal;*/
/*  border: 1px solid black;*/
/*  border-radius: 50%;*/
/*}*/

/*.fa-chevron-right:after{*/
/*  content: '>';*/
/*  display: block;*/
/*  width: 20px;*/
/*  height: 20px;*/
/*  line-height: 19px;*/
/*  font-style: normal;*/
/*  border: 1px solid black;*/
/*  border-radius: 50%;*/
/*}*/

.fa-chevron-right{
  transform: rotate(180deg);
  display: block;
}

.slider-block >>> .agile__actions{
  height: 0;
  margin-top: -60px;
  width: calc(100% + 60px);
  margin-left: -30px;
  margin-bottom: 60px;
}

</style>
